import consumer from "./consumer"

consumer.subscriptions.create("ContactMessagesChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log('Connected to Contact Messages Room!');
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    // console.log("Recibi el mensaje");
    //console.log(data.message_html);
    getTopNotifications();
    var escalated_messages_view = ($("#escalated_messages_view").length > 0),
        closed_escalations_view = ($("#escalated_messages_view #chat-contacts[data-filter='own_inbox_close']").length > 0);

    if (!closed_escalations_view) {

      //chat contacts headers
      changeContactsHeaders(data.contact, data.phone_number);

      //contact card moved to top
      if (!escalated_messages_view) {
        if (['', undefined].indexOf($('.js-title-cc').attr('data-entity')) >= 0 || ((data.contact == '' && data.phone_number != '') || (data.contact != '' && $(".cc-contact-card[data-contact-id='" + data.contact + "']").length > 0))) {
          if (data.contact != '') {
            if (data.entity_type == 'Contact' || data.entity_type == undefined)
              $(".contact-card[data-contact-id='" + data.contact + "']").remove();
            else if (data.entity_type == 'AccountUser')
              $(".account-user-card[data-contact-id='" + data.contact + "']").remove();
            else if (data.entity_type == 'Mentor')
              $(".mentor-card[data-contact-id='" + data.contact + "']").remove();
          } else
            $(".contact-card[data-phone-number='" + data.phone_number + "']").remove();

          var accountIds = $('#chat-contacts').data('accountIds')
          if (data.outbound_msg != undefined || (data.message_intro == undefined && $("#chat-contacts").data('filter') == 'own_inbox_open' && (accountIds.includes(data.account_id) || accountIds == undefined)))
            $("#chat-contacts.sms").prepend(data.contact_html);
        }
      }

      if (data.track_action != undefined) {
        $("#list_of_comments").append(data.track_action);
        $("#new-message #opted_out").val(true);

        var $optedOutSpan = "<span id='opted-out-span' style='vertical-align: top; color: #f98e57; font-size: .77rem;'><i class='fas fa-ban' data-toggle='tooltip' title='Opted-out'></i> <span class='hide-sm mr-1'>Opted-Out</span></span>";
        $('.last-seen').prepend($optedOutSpan);
        $('.block-outbound-link').replaceWith("<a class='dropdown-item unblock-outbound-link' data-confirm='Are you sure you want to unblock the outbound messages?' data-remote='true' rel='nofollow' data-method='post' href='/contact_center/" + data.contact + "/unblock_outbound'>Unblock Outbound</a>");
      }
      //if (data.options_send != undefined) $(".controls.js-options-send").html(data.options_send);


      //messages
      prepareMessageAddition(data.contact, data.phone_number);

      var commentsList = null,
          messagesSection = null,
          comment = null;
      if (data.contact != '' && (data.entity_type == 'Contact' || data.entity_type == undefined)) {
        commentsList = $("#list_of_comments[data-contact-id='" + data.contact + "']");
        messagesSection = $("#chat-messages[data-contact-id='" + data.contact + "']");
      } else {
        commentsList = $("#list_of_comments[data-phone-number='" + data.phone_number + "']");
        messagesSection = $("#chat-messages[data-phone-number='" + data.phone_number + "']");
      }

      if (commentsList.length > 0) {
        comment = $(".comment-card[data-comment-id='" + data.message_id + "']");
        if (comment.length > 0) {
          comment.replaceWith(data.message_html);
        } else {
          commentsList.append(data.message_html);
        }

        if (commentsList.data('inbdOptions') == false)
          commentsList.find('.comment-card').last().find('.inbound-menu').remove();

        var objDiv = document.getElementById("messages-list");
        if (objDiv)
          objDiv.scrollTop = objDiv.scrollHeight;

        checkAppearedComments();

        if (data.valid_24_hour_session_html)
          refreshControlsValidSession(data.valid_24_hour_session_html);
      }

      //close_contact_center?
      if (data.outbound_msg != undefined) {
        if ( ($("#chat-contacts").data('filter') == 'own_inbox_open' && data.open_contact_center == false) ||
             ($("#chat-contacts").data('filter') == 'own_inbox_close' && data.open_contact_center == true) ) {
          $(".cc-contact-card[data-contact-id='" + data.contact + "']").remove();

          //Show an alert and the message intro
          var userId = $('#chat-messages').data('msgu').replace('mu', ''),
              action = data.open_contact_center == true ? 'opened' : 'closed';
          if (userId != data.user_id && messagesSection.length > 0)
            alert(data.user_name + ' ' + action + ' this conversation')
          messagesSection.html(data.message_intro);
        }

        if (data.message_id != undefined) {
          setTimeout(function() {
            $.getScript("/messages/" + data.message_id + "/check_delivery");
          }, 4000);
        }
      } else if ($("#chat-contacts").data('filter') == 'own_inbox_close' && messagesSection.length > 0)
        messagesSection.html($("#messages-intro-container").html());

      $(".menu-option[data-message-type='sms'] .unread-messages-count").removeClass('d-none');
      var qMessages = $(".menu-option[data-message-type='sms'] .q-unread-messages").text().trim();
      qMessages = qMessages != '' ? parseInt(qMessages) + 1 : 1;
      $(".menu-option[data-message-type='sms'] .q-unread-messages").text(qMessages);
      $(".menu-option[data-message-type='all'] .unread-messages-count").removeClass('d-none');

      $.getScript("/contact_center/filter_contacts?message_type=sms&filter=own_inbox_open&refresh_sidebar=true");
    }
  }
});
