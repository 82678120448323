import consumer from "./consumer"

consumer.subscriptions.create("ConversationChannel", {
  // Called once when the subscription is created.
  // initialized() {
  //   console.log('initialized ConversationChannel Room!');
  // },

  // Called when the subscription is ready for use on the server.
  connected() {
    console.log('Connected to ConversationChannel!');
  },

  // Called when the WebSocket connection is closed.
  disconnected() {
    // console.log('disconnected from ConversationChannel!');
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    // if user is inside the contact center
    if (data.event != undefined && data.event == "sent") {
      var current_u_id = parseInt($("#imLink").data("current-u"));
      var sender_id = parseInt(data.sender_id);
      var recipient_ids = data.recipient_ids;

      // check if user is in the conversation
      if (recipient_ids.includes(current_u_id)) {
        if (current_u_id != sender_id) {
          var ims_count = parseInt($("#imLink").data("ims-count"));
          if (ims_count > 0) {
            $("#imLink").find(".badge").html(ims_count + 1);
            $("#imLink").data("ims-count", ims_count + 1);
            $("#imLink").attr("data-ims-count", ims_count + 1);
          } else {
            $("#imLink").append("<span class='badge elevatero_badge bg_red float-right mt--3 ml--3'>1</span>");
            $("#imLink").data("ims-count", 1);
            $("#imLink").attr("data-ims-count", 1);
          }
        }
        $(".internal_list_of_comments_"+data.convo_id).append(data.html);

        if ($("#windowChat-"+data.convo_id).length > 0) {
          var user_id = $("#windowChat-"+data.convo_id).data("current-u");
          if (parseInt(user_id) != sender_id) {
            $("#chatComment-"+data.message_id).removeClass("text-right");
            $("#chatComment-"+data.message_id).find(".comment-body").removeClass("outbound").addClass("inbound");
            var permissions = data.recipient_permissions[current_u_id];
            if (permissions) {
              if (permissions.sound) {
                playNotification("conversation");
              }

              if (permissions.desktop) {
                createNotification(data.sender_name, data.message_preview);
              }
            }
          }
          if ($("#windowChat-"+data.convo_id).find(".messages-body").isInViewport()) {
            scrollToUnread(data.convo_id, 0);
            markChatAsRead($("#windowChat-"+data.convo_id));
          } else {
            $("#windowChat-"+data.convo_id).addClass("pending");
          }
        } else if (current_u_id != sender_id) {
          // open window with pending class minimized
          $.ajax({
            url: '/conversations/open',
            data: {
              convo_id: data.convo_id,
              minimized: true
            }
          });

          var permissions = data.recipient_permissions[current_u_id];
          if (permissions) {
            if (permissions.sound) {
              playNotification("conversation");
            }

            if (permissions.desktop) {
              createNotification(data.sender_name, data.message_preview);
            }
          }

        }
      }

    } // if (data.event == "sent")

    // Open/Close conversation
    if (data.toggle_status != undefined) {
      if ( ($("#chat-contacts").data('filter') == 'own_inbox_open' && data.open_contact_center == false) ||
           ($("#chat-contacts").data('filter') == 'own_inbox_close' && data.open_contact_center == true) ) {
        if (data.contact_id != '')
          if (data.entity_type == 'Contact' || data.entity_type == undefined)
            $(".cc-contact-card[data-contact-id='" + data.contact_id + "']").remove();
          else if (data.entity_type == 'AccountUser')
            $(".account-user-card[data-contact-id='" + data.contact_id + "']").remove();
          else if (data.entity_type == 'Mentor')
            $(".mentor-card[data-contact-id='" + data.contact_id + "']").remove();
        else
          $(".cc-contact-card[data-phone-number='" + data.phone_number + "']").remove();
      } else
        $("#chat-contacts.sms").prepend(data.contact_html);

      var chatMessages = null;
      if (data.contact_id != '' && (data.entity_type == 'Contact' || data.entity_type == undefined))
        chatMessages = $("#chat-messages[data-contact-id='" + data.contact_id + "']");
      else
        chatMessages = $("#chat-messages[data-phone-number='" + data.phone_number + "']");
      chatMessages.html(data.message_intro);
      //console.log(chatMessages.length)

      //Show an alert
      if ($('#chat-messages').data('msgu') != undefined)
        var userId = $('#chat-messages').data('msgu').replace('mu', ''),
            action = data.open_contact_center == true ? 'opened' : 'closed';
        if (userId != data.user_id && chatMessages.length > 0)
          alert(data.user_name + ' ' + action + ' this conversation')

      chatMessages.removeAttr('data-contact-id');
      chatMessages.removeAttr('data-phone-number');

      if (data.open_contact_center == false)
        $.getScript("/contact_center/filter_contacts?message_type=sms&filter=own_inbox_open&refresh_sidebar=true");
    }
  }
})


// $(function() {
//   $('[data-channel-subscribe="conversation"]').each(function(index, element) {
//     var $element = $(element),
//         room_id = $element.data('convo-id');
//         // messageTemplate = $('[data-role="message-template"]');
//
//     // $element.animate({ scrollTop: $element.prop("scrollHeight")}, 1000)
//
//     consumer.subscriptions.create(
//       {
//         channel: "ConversationChannel",
//         room: convo_id
//       },
//       {
//         received: function(data) {
//           console.log(data);
//         }
//       }
//     );
//   });
// });
