import consumer from "./consumer"

consumer.subscriptions.create("RinglessVoicemailsChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    // console.log('Connected to Ringless Voicemails Room!');
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    // console.log("Recibi el mensaje");
    // console.log(data.message_id);

    var commentsList = null,
        commentCard = null,
        spanStatus = null;
    if (data.contact != '')
      commentsList = $("#list_of_comments[data-contact-id='" + data.contact + "']");
    else
      commentsList = $("#list_of_comments[data-phone-number='" + data.phone_number + "']");

    if (commentsList.length > 0) {
      commentCard = commentsList.find(".comment-card[data-comment-id='" + data.message_id + "']");
      spanStatus = commentCard.find(".comment-footer span.fe-check.text-black-50");
      if (data.sent_at != '') {
        spanStatus.removeClass('fe-check text-black-50').addClass('fe-check text-success');
      }
      else {
        spanStatus.removeClass('fe-check text-black-50').addClass('fe-x text-danger');
      }

      var objDiv = document.getElementById("messages-list");
      if (objDiv)
        objDiv.scrollTop = objDiv.scrollHeight;
    }

  }
});
